import React, { Component, useState } from "react";
//    importing components
import { Buttons } from "../../../../Components/AbstractsComponents/Button/button";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { Link } from "react-router-dom";
//    importing images
import CarImage from "../../../../Assests/Images/image 2.png";
//    importing icons
import EditIcon from "../../../../Assests/Icons/edit-buton.png";
import OfferIcon from "../../../../Assests/Icons/offerButonIcon.png";
//    imoporting css files
import "./allVehicleTable.css";
import CustomPagination from "../../../ModuleComponents/CustomPagination";
import VehiclesListSkeleton from "../../../ModuleComponents/Skeleton/vehiclesList";
import Pagination from "../../../AbstractsComponents/Pagination";
import VehiclesController from "../../../../Controllers/vehiclesController";
import ToastController from "../../../../Controllers/toastController";
import { AiFillDelete } from "react-icons/ai";
import { Modals } from "../../../AbstractsComponents/Modal/modal";

var products = [
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
    action: "action. ID",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-151 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-152 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-153 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford G-155 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford K-154 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2017 Ford F-150 LT",
    offer: "$10",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "10 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "20 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 CAR F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 For F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-151 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-152 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-153 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford G-155 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford K-154 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2017 Ford F-150 LT",
    offer: "$10",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "10 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "20 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 CAR F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 For F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
  {
    vehiclePicture: CarImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$120",
    age: "1 day",
  },
];
function imageFormatter(cell, row) {
  return (
    <img
      style={{ width: 70, height: 50, borderRadius: 10 }}
      src={cell}
      alt=""
    />
  );
}

// let toast = new ToastController();

// const handleStatusChange = (id, status) => {
//   console.log("id, status....", id, status);
//   let data = {
//     _id: id,
//     enabledStatus: status,
//   };
//   toast.showProcessing("Changing Vehicle Status");

//   VehiclesController.changeVehicleStatus(data)
//     .then((res) => {
//       toast.endProcessingWithSuccess("Status Changed!");
//     })
//     .catch((err) => {
//       toast.endProcessingWithError(err);
//     });
// };

function ButtonFormat({ cell, row, show, setState }) {
  // const [show, setShow] = useState(false);
  // console.log(`#VehicleStatus:${row.payload._id}`, row.payload)
  // console.log("ROW CONTENT..............",row.payload.vehicleSellingStatus)
  switch (row.payload.vehicleSellingStatus) {
    case "negotiation":
      return (
        <div key={row?.payload?._id} className="All-vehicle-button-div">
          <Link
            key={`3`}
            to={`/allVehicle/offerVehicle/${row.payload._id}`}
            className="react-router-link"
          >
            <Buttons acceptedOfferButton RightIcon={OfferIcon}>
              Accepted
            </Buttons>
          </Link>
        </div>
      );
    case "sold":
      return (
        <div key={row.payload._id} className="All-vehicle-button-div">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 150,
            }}
          >
            <h6 style={{ fontStyle: "italic" }}>Sold</h6>
          </div>
          {/* <Link key={`3`} to={`/allVehicle/offerVehicle/${row.payload._id}`} className="react-router-link">
              <Buttons offerButton RightIcon={OfferIcon}>
                Sold
              </Buttons>
            </Link> */}
        </div>
      );

    default:
      return (
        <div>
          <div key={row.payload._id} className="All-vehicle-button-div">
            <Link
              key={`1`}
              to={`/allVehicle/editVehicle/${row.payload._id}`}
              className="react-router-link"
            >
              <Buttons editButton RightIcon={EditIcon}>
                Edit
              </Buttons>
            </Link>
            <div key={`2`} className="all-vehicle-button-seprate"></div>
            <Link
              key={`3`}
              to={`/allVehicle/offerVehicle/${row.payload._id}`}
              className="react-router-link"
            >
              <Buttons
                badgeValue={row.payload.numberOfOffers}
                offerButton
                RightIcon={OfferIcon}
              >
                Offers
              </Buttons>
            </Link>
            {/* <div key={`3`} className="all-vehicle-button-seprate"></div>
            <div className="py-2 px-1 text-center font-[400] bg-white border border-gray-700 rounded-lg h-10 w-[100px]">
              <select
                className="w-full h-full text-center bg-white text-[16px]"
                onChange={(e) => {
                  handleStatusChange(row.payload._id, e.target.value);
                  console.log("Vehicle.....", row.payload);
                }}
                defaultValue={`${
                  row.payload.enabledStatus === 1 ? "Enabled" : "Disabled"
                }`}
              >
                <option disabled selected hidden>
                  {` ${
                    row.payload.enabledStatus === 1 ? "Enabled" : "Disabled"
                  }`}
                </option>
                <option value={1}>Enable</option>
                <option value={0}>Disable</option>
              </select>
            </div>
            <div key={`4`} className="all-vehicle-button-seprate"></div>
            <button
              onClick={() => {
                setState({
                  show: true,
                });
              }}
              className="w-[100px] flex gap-1 items-center justify-center p-2 h-10 rounded-lg bg-red-500 text-white font-[400] text-[16px]"
            >
              Delete <AiFillDelete />
            </button> */}
          </div>
        </div>
      );
  }
}
class VehicleTable extends Component {
  state = {
    show: false,
  };

  render() {
    // console.log("Loading status", this.props.loading)
    let renderShowsTotal = () => {
      return (
        <>
          <p className="d-flex justify-content-between pagination-container">
            <div className="show-table-data-text">
              Showing {this.props.activePageNo} from {this.props.noOfPages}
            </div>
            <CustomPagination
              noOfPages={this.props.noOfPages}
              onPageChange={this.props.setPageNo}
              activePageNo={this.props.activePageNo}
              pageNoArray={this.props.pageNoArray}
              setPageNoArray={this.props.setPageNoArray}
            />

            {/* <Pagination
              noOfPages={this.props.noOfPages}
              currentPage={this.props.activePageNo}
            /> */}
          </p>
        </>
      );
    };

    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "All",
          value: this.props.data?.length,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: 20, // which size per page you want to locate as default
      pageStartIndex: 0, // where to start counting the pages
      paginationSize: this.props.noOfPages + 1, // the pagination bar size.
      prePage: "<<", // Previous page button text
      nextPage: ">>", // Next page button text
      firstPage: ">", // First page button text
      lastPage: ">>", // Last page button text
      paginationShowsTotal: renderShowsTotal, // Accept bool or function
      hidePageListOnlyOnePage: true,
    };

    return (
      <div style={{ display: "block", height: "70vh" }}>
        <BootstrapTable
          data={this.props.data ? this.props.data : products}
          options={options}
          bodyContainerClass="my-custom-body"
          headerStyle={{ padding: "0px 0px" }}
          tableStyle={{ borderRadius: "30px", border: "none" }}
          tableContainerClass="my-custom-table-class"
          bodyStyle={{
            overflow: "overlay",
            // height: "calc(100vh - 350px)",
            background: "#ffffff",
            borderRadius: "30px",
          }}
          maxHeight="500px"
          pagination={true}
        >
          <TableHeaderColumn
            thStyle={{ padding: "20px 40px" }}
            tdStyle={{ padding: "10px 0px 10px 40px", verticalAlign: "middle" }}
            width="160px"
            className="td-header-string-example"
            isKey
            dataField="vehiclePicture"
            dataFormat={imageFormatter}
          >
            <div style={{ display: "flex" }}>
              Vehicle Picture
              {/* <div className='header-arrow-div'>
                        <img src={UpArrow} className='header-arrows'/>
                        <img src={DownArrow} className='header-arrows'/>
                    </div> */}
            </div>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="vehicleName"
            tdStyle={{
              color: "#16478E",
              fontWeight: "700",
              verticalAlign: "middle",
            }}
            width="300px"
            className="td-header-string-example"
          >
            <div style={{ display: "flex" }}>
              Vehicle Name
              {/* <div className='header-arrow-div'>
                        <img src={UpArrow} className='header-arrows'/>
                        <img src={DownArrow} className='header-arrows'/>
                    </div> */}
            </div>
          </TableHeaderColumn>

          <TableHeaderColumn
            className="td-header-string-example"
            width="120px"
            tdStyle={{
              padding: "10px",
              fontWeight: "700",
              verticalAlign: "middle",
            }}
            dataField="priceRange"
          >
            <div style={{ display: "flex" }}>Price</div>
          </TableHeaderColumn>

          <TableHeaderColumn
            className="td-header-string-example"
            tdStyle={{ fontWeight: "700", verticalAlign: "middle" }}
            width="90px"
            dataField="age"
          >
            <div style={{ display: "flex" }}>
              Created at
              {/* <div className='header-arrow-div'>
                        <img src={UpArrow} className='header-arrows'/>
                        <img src={DownArrow} className='header-arrows'/>
                    </div> */}
            </div>
          </TableHeaderColumn>

          <TableHeaderColumn
            className="td-header-string-example"
            dataField="action"
          ></TableHeaderColumn>

          <TableHeaderColumn
            className="td-header-string-example"
            tdStyle={{
              padding: "10px 0px 10px 0px",
              borderRadius: "30px",
              fontWeight: "700",
              verticalAlign: "middle",
              position: "sticky",
              right: "0px",
              textAlign: "right",
            }}
            thStyle={{
              position: "sticky",
              right: "0px",
              backgroundColor: "white",
              borderRadius: "30px",
            }}
            dataField="action"
            width="280px"
            dataFormat={(cell, row) => (
              <ButtonFormat
                cell={cell}
                row={row}
                show={this.state.show}
                setState={this.setState}
              />
            )}
            dataAlign="right"
          >
            <div
              style={{
                display: "flex",
                marginLeft: "20px",
              }}
            >
              Actions
            </div>
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

export default VehicleTable;
